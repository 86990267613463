<template>
  <div class="simulateReport">
    <div class="title">
      <h2>Simulate Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :model="simReport" :inline="true">
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Target(s)</label>
                </el-col>
                <el-form-item label="Countries" label-width="1.8rem">
                  <el-col :lg="6" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="simReport.countries"
                      style="width:3.5rem;"
                      placeholder="eg. US,IT"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="Package" label-width="1.2rem">
                  <el-col :lg="7" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="simReport.pkgList"
                      style="width:3.5rem;"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="simReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.number"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Month">
                    <el-checkbox v-model="monthChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Date">
                    <el-checkbox v-model="dateChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Hour">
                    <el-checkbox v-model="hourChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              ref="tableRef"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="tableDataList"
              highlight-current-row
            >
              <el-table-column
                label="Date"
                prop="date"
                fixed="left"
                align="center"
                min-width="170"
                key="date"
              ></el-table-column>

              <el-table-column
                label="Prod Name"
                prop="pkg"
                min-width="120"
                align="center"
                key="pkg"
                v-if="packageChecked"
              ></el-table-column>
              <el-table-column
                label="Country"
                prop="country"
                min-width="100"
                align="center"
                key="country"
                v-if="countriesChecked"
              ></el-table-column>
              <el-table-column
                label="Count"
                prop="count"
                min-width="100"
                align="center"
                key="count"
              ></el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageParam.pageSize"
              :currentPage="pageParam.page"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { getSimulateReport } from 'api/report';

  import Pagination from '@/components/pagination';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dataParam: null,
        fullWidth: document.documentElement.clientWidth,
        tableDataList: [],
        total: 0,
        listLoading: false,
        summariesMap: {},
        startToEndDate: '',
        timezoneOption: optionData.timezoneOption,
        simReport: {
          countries: null,
          pkgs: null,
          fromDate: null,
          toDate: null,
          timezone: 0,
          columns: [],
        },
        countriesChecked: true,
        packageChecked: true,
        hourChecked: true,
        dateChecked: true,
        monthChecked: false,
      };
    },
    computed: {
      listenChange() {
        const { packageChecked, countriesChecked } = this;
        return {
          packageChecked,
          countriesChecked,
        };
      },
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      searchListClick(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.simReport,
          ...this.pageParam,
        };
        if (this.countriesChecked == true && param.columns.indexOf('country') == -1) {
          param.columns.push('country');
        } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
          const countryIndex = param.columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          param.columns.splice(countryIndex, 1);
        }
        if (this.packageChecked == true && param.columns.indexOf('pkg') == -1) {
          param.columns.push('pkg');
        } else if (this.packageChecked == false && param.columns.indexOf('pkg') !== -1) {
          const prodIndex = param.columns.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          param.columns.splice(prodIndex, 1);
        }

        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
          param.columns.push('date');
        } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }

        this.pageParam.page = 1;

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.columns)) {
          dataParam.columns = dataParam.columns.join(',');
        }
        this.dataParam = dataParam;
        this.getSimulateReport();
      },
      getSimulateReport() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        getSimulateReport(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            if (response.result.length == 0) {
              this.$message({
                message: '数据列表为空',
                type: 'warning',
              });
            }

            const summariesMap = response.result.pop() || [];
            const result = response.result || [];
            if (result.length <= 0) {
              return;
            }
            this.tableDataList = result;
            this.total = response.total;
            let totalObject = new Object();
            totalObject.date = 'Total';
            totalObject.count = summariesMap.count;
            this.tableDataList.push(totalObject);
          } else {
            this.tableDataList = [];
            this.listLoading = false;
            this.$message.error('Error: ' + response.message);
          }
        });
      },
      filterNull(val) {
        return val == null ? 0 : val;
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.getSimulateReport();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
